import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming'
import theme from './theme'

import Home from './views/Home/Home';
import Nosotros from './views/Nosotros/Nosotros';
import Contabilidad from './views/Servicios/Contabilidad';
import Nomina from './views/Servicios/Nomina';
import IMSS from './views/Servicios/IMSS';
import Pensiones from './views/Servicios/Pensiones';
import Contacto from './views/Contact/Contact';
import Exportaciones from './views/Exportaciones/Exportaciones'

import { Fade } from "react-awesome-reveal"

function App() {
  
  return (
        <div className="App">
        	<ThemeProvider theme={theme}>

    	        <Switch>
                    {/*<Fade delay={200}>*/}
                        <Route path="/" exact component={Home} />
                        <Route path="/nosotros" exact component={Nosotros} />
                        <Route path="/servicios/contabilidad" exact component={Contabilidad} />
                        <Route path="/servicios/nomina" exact component={Nomina} />
                        <Route path="/servicios/imss" exact component={IMSS} />
                        <Route path="/servicios/pensiones" exact component={Pensiones} />
                        <Route path="/exportaciones" exact component={Exportaciones} />
                        <Route path="/contacto" exact component={Contacto} />
                        <Redirect path="*" to="/" /> 
                    {/*</Fade>*/}
    	        </Switch>
          
      		</ThemeProvider>
        </div>
  );
}

export default App;
