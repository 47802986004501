import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import styles from './Contacto.module.css';
import LocalizedStrings from 'react-localization';
import { useHistory } from 'react-router-dom';

import Input from '../../../components/UI/Input/Input';
import * as util from './util';
import { motion } from "framer-motion"
import mobile from '../../../assets/mobile.svg'
import email from '../../../assets/email.svg'
import phone from '../../../assets/phone.svg'

import { ReactComponent as Pens } from '../../../assets/svg/pensiones.svg'
import { ReactComponent as Conta } from '../../../assets/svg/contabilidad.svg'
import { ReactComponent as Imss } from '../../../assets/svg/imss.svg'
import { ReactComponent as Nom } from '../../../assets/svg/nomina.svg'


import isotipo from '../../../assets/isotipoContacto.svg'
import send from '../../../assets/sendArrowColor.svg'
import instagram from '../../../assets/instagram.svg'
import facebook from '../../../assets/facebook.svg'
import twitter from '../../../assets/twitter.svg'
import contact from '../../../assets/sections/contact/Contact.png'


const Contacto = (props) => {
	const history = useHistory();
	const [selectedRadio, setSelectedRadio] = useState("");

	let trans = new LocalizedStrings({
		es: {
			text: "Contáctanos",
			name: "Nombre",
			message: "Mensaje",
			send: "Enviar",
			title: "Enviado",
			textSwal: "Nos pondremos en contacto contigo",

		},
		en: {
			text: "Contact us",
			name: "Name",
			message: "Message",
			send: "Send",
			title: "Sent",
			textSwal: "We will contact you",

		}
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [form, setForm] = useState({
		name: {
			elementType: 'input',
			elementLabel: 'Nombre',
			elementConfig: {
				type: 'text',
				// placeholder: "NOMBRE",
			},
			value: '',
			validation: {
				required: false,
				minLength: 4,
			},
			valid: false,
			touched: false,
			errorType: []
		},
		email: {
			elementType: 'input',
			elementLabel: 'Email',
			elementConfig: {
				type: 'email',
				// placeholder: 'EMAIL',
			},
			value: '',
			validation: {
				required: true,
				isEmail: true
			},
			valid: false,
			touched: false,
			errorType: [],
		},
		organizacion: {
			elementType: 'text',
			elementLabel: 'Organización',
			elementConfig: {
				type: 'text',
				// placeholder: "ORGANIZACIÓN",
			},
			value: '',
			validation: {
				required: false,
				minLength: 2,
			},
			valid: false,
			touched: false,
			errorType: []
		},
		telefono: {
			elementType: 'text',
			elementLabel: 'Teléfono',
			elementConfig: {
				type: 'text',
				// placeholder: "TELÉFONO",
			},
			value: '',
			validation: {
				required: false,
				minLength: 10,
			},
			valid: false,
			touched: false,
			errorType: []
		},
		message: {
			elementType: 'textarea',
			elementLabel: 'Comentarios',
			elementConfig: {
				type: 'text',
				// placeholder: "COMENTARIOS",
			},
			value: '',
			validation: {
				required: false,
				minLength: 10,
			},
			valid: false,
			touched: false,
			errorType: []
		},

	});

	const radioOptions = [
		{
			id: 1,
			label: 'Contabilidad',
			value: "contabilidad",
			svg: <Conta />
		},
		{
			id: 2,
			label: 'Nomina',
			value: "nomina",
			svg: <Nom />
		},
		{
			id: 3,
			label: 'IMSS',
			value: 'imss',
			svg: <Imss />
		},
		{
			id: 4,
			label: 'Pensiones',
			value: 'pensiones',
			svg: <Pens />
		},
	];

	const _radioElements = radioOptions.map(radioElement => (
		<div key={"div" + radioElement.id}>
			<Input
				key={radioElement.id}
				elementType="radio"
				label={radioElement.label}
				name="servicio"
				value={radioElement.value}
				onChangeFunction={(event) => util.radioHandler(event, setSelectedRadio)}
				selected={selectedRadio}
				imgSvg={radioElement.svg}
			/>
		</div>
	));


	const [formIsValid, setFormIsValid] = useState(false);

	const submitHandler = (event) => {
		event.preventDefault();
		if (formIsValid && selectedRadio) {
			console.log("valido");
			// axios.post('https://getform.io/f/bb710f92-c37c-43a3-9329-91951ce6dfb8', {
			//           name: form.name.value,
			//           email: form.email.value,
			//           message: form.message.value,
			//    })
			//    .then((response) => {
			//        Swal.fire({
			// 	  title: trans.title,
			// 	  text: trans.textSwal,
			// 	  icon: 'success',
			// 	});
			// 	setForm({...form,
			// 		name:{...form.name,value:""},
			// 		email:{...form.email,value:""},
			// 		message:{...form.message,value:""}})
			//    })
			//    .catch((error) => {
			//        console.log(error);
			//    });
			Swal.fire({
				title: trans.title,
				text: trans.textSwal,
				icon: 'success',
			});
		}
	}

	const formElementsArray = [];
	for (let key in form) {
		formElementsArray.push({
			id: key,
			config: form[key]
		});
	}

	const _form = formElementsArray.map(formElement => (
		<div key={"div" + formElement.id}>
			<Input
				key={formElement.id}
				elementLabel={formElement.config.elementLabel}
				changed={(event) => util.inputChangeHandler(event, formElement.id, form, setForm, setFormIsValid)}
				elementType={formElement.config.elementType}
				elementConfig={formElement.config.elementConfig}
				invalid={!formElement.config.valid}
				errorsArray={formElement.config.errorType}
				touched={formElement.config.touched}
				shouldValidate={formElement.config.validation}
				value={formElement.config.value} />
		</div>
	));


	return (
		<div className={styles.contact}>
			<div className={styles.contactLeft}>
				<h1 className={styles.Title}>CONTACTO</h1>
				<span>¿En que podemos ayudarte?</span>
				<form onSubmit={submitHandler} className={styles.Form}>
					<div className={styles.radioContainer}>
						{_radioElements}
						{
							formIsValid && !selectedRadio ?
								<span className={styles.error}>Selecciona una opción</span>
								: null
						}
					</div>
					<div className={styles.InputContainer}>
						{_form}
					</div>
					<div className={styles.ButtonContainer}>
						<button type="submit" className={styles.Button}>{trans.send}</button>
					</div>
				</form>
			</div>
			<div className={styles.contactRight}>
				<a className={styles.tagImg} href="https://goo.gl/maps/W8kqLY7QjhDiAbW96" target="_blank">
					<img className={styles.contactImg} src={contact} />
				</a>
			</div>
			<div className={styles.backgroundImg}></div>
		</div>
	);
}

export default Contacto;