import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrowColor.svg'
import styles from './Nosotros.module.css';
import nosotros from "../../../assets/sections/about/about1.png"
import imgX from '../../../assets/exportacionesMobile.png'
import arrowDown from "../../../assets/arrowDown.svg"
import waterMark from "../../../assets/waterMark.svg"
import { motion } from "framer-motion"

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    
  },

};

const Nosotros = (props) => {
	const history = useHistory();
	const [isVisible,setIsVisible] = useState(false)

	let trans = new LocalizedStrings({
	 es:{
		title: "NOSOTROS",
		subtitle: "Nos especializamos en contabilidad para Pymes. Ofrecemos asesorías administrativas, contables, financieras y planes de negocio.",
		seeMore: "CONÓCENOS"
	 }
	});

	const handleScroll = e => {

		if (window.scrollY >= 10 && window.scrollY < (props.refsTo.Servicios.current.offsetTop -250)) {
			setIsVisible(true);
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
		}
	},[]);

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);




	return(
			<Box className={styles.Container} marginBottom={[0,0,60]} id="Nosotros" onClick={() => {setIsVisible(true)}}>
			<div className={"MaxDimensions"} style={{position:"relative"}}>
				
					<Flex flexDirection={["column","column","row"]} >
						<motion.div className={styles.animatedDiv} 
							style={{position:"relative",justifyContent:"center",display:"flex",padding:"0"}}
							initial={{ opacity: 0, y: 1000 }} animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 1000}}
							transition={{ duration: 1 }}>
							<Image maxHeight="680px" height={["219px","auto"]} sx={{zIndex:2,objectFit:["cover","cover"]}}  width={["313px","100%","100%"]}src={nosotros}/>
						</motion.div>
						<Flex flexDirection="row" flexWrap="wrap" alignContent="center" width={["100%","100%","65.5%"]}  paddingLeft={[0,0, 100]} >
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -1000}} transition={{ duration: 1 }}>
							<Flex justifyContent="flex-start" alignItems="flex-start">
								<Text
									fontWeight="100"
									fontFamily="MadeSonara" 
									letterSpacing={[0,"0px","0px"]}
									// sx={{textShadow:"#00000052 0 9px 6px"}} 
									fontSize={[40, 70, 70]}
									color="#2E333E"
									sx={{textShadow:"0px 3px 6px #00000029"}}
									>{trans.title}</Text>
							</Flex>
							</motion.div>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -1000}} transition={{ duration: 1 }} >
							<Flex  flexDirection="column"  mt={[30]} mb={[35,35,70]} justifyContent="center" alignItems="flex-start">
{/*								<Text 
									maxWidth={[337,"100%",466]}
								 	mb={[30]}
									fontWeight="700"
									textAlign="left" 
									lineHeight={["26px","38px"]} 
									color="#5D656F"
									letterSpacing="1.25px"
									fontSize={[25,25]}>Optimizamos procesos en las empresas para aumentar rentabilidad.</Text>*/}
								<Text 
									maxWidth={["100%","100%",466]}
								 	mb={[30]}
									textAlign="justify" 
									lineHeight={["26px","35px"]} 
									letterSpacing="1px"
									color="#5D656F"
									fontSize={[20]}>Nos especializamos en <span style={{fontWeight: "bold"}}>contabilidad para Pymes.</span> Ofrecemos asesorías administrativas, contables, financieras y planes de negocio.</Text>
									<button className={styles.Button} onClick={() => history.push("/nosotros")}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div style={{fontWeight:700}} className="hoverWhite">{trans.seeMore}</div>
										</div>
									</button>
							</Flex>
							</motion.div>
						</Flex>
					</Flex>
			</div>
			</Box>
	);
}

export default Nosotros;