import React from 'react';
import {NavLink, useHistory} from 'react-router-dom'

import isotipo from '../../assets/svg/logo2.svg'
import isotipo2 from '../../assets/isotipo2.svg'
import styles from  './Logo.module.css'

const Logo = (props) => {
	const history = useHistory()
	const handleClick = () => {
		// if(history.location.pathname === "/es/" || history.location.pathname === "/en/" || history.location.pathname === "/es" || history.location.pathname === "/en"){
		if(history.location.pathname === "/"){
			window.scrollTo({top:0,behavior: 'smooth'})
		}
		else{
			history.push("/")
		}
		if(props.closed){
			props.closed()

		}
	}

	return(
		<div
			onClick={handleClick}>
			<div className={styles.Logo}>
				<img src={props.atTop ? isotipo : isotipo} alt="Logo"/>
			</div>
		</div>
	);

}

export default Logo;